import { Container, Heading } from '@chakra-ui/react';
import React from 'react';
import ScrollComponent from '../components/ScrollComponent';
import MaskSlidInAnimation from '../DefinitionComponents/Animation-Components/MaskSlideInAnimation';
import ScrollcompontentListelement from './ScrollcompontentListelement';

function ScrollComponent6LawsCore() {
  return (
    <ScrollComponent
      video={true}
      scrollLength={{ base: '100%' }}
      progressColor={'brand.green'}
      sticky={false}
      scrollStartFactor={0.3}
      scrollEndFactor={0.1}
      mediaSrc={
        '302086_Clouds_Tower_Antenna_Sky_by_Via_Films_Artgrid-HD_H264-HD-1280web.mp4'
      }
      mediaOpacity={0.5}
      bgColor={'white'}
      initialMinWidth={{ base: '90%', lg: '90%' }}
      //initialMinWidth={ "89%" }}
      initialWidth={'50%'}
      initialPaddingX={'2rem'}
      animationStartThreshold={1}
      animationEndThreshold={90}
      duration={1.3}
      delay={0}
      bezierCurve={[0.63, -0.02, 0.34, 1.02]}>
      <Container
        variant="layoutContainer"
        px={{ base: 0, lg: 12 }}
        pt={36}>
        <MaskSlidInAnimation
          threshold={1}
          duration={0.6}>
          <Heading
            fontSize={{ base: '4xl', lg: '6xl' }}
            maxW="100%"
            fontWeight="normal"
            letterSpacing="tighter"
            color="brand.blue"
            w={{ lg: '100%', base: '100%' }}
            mx="auto">
            6 Core Values
          </Heading>
        </MaskSlidInAnimation>
        <MaskSlidInAnimation
          threshold={1}
          duration={0.6}>
          <Heading
            fontSize={{ base: '2xl', lg: '3xl' }}
            maxW="100%"
            fontWeight="normal"
            mb="64"
            letterSpacing="tighter"
            color="brand.blue"
            w={{ lg: '100%', base: '100%' }}
            mx="auto">
            Our investment strategy. Our character. Our experience.
          </Heading>
        </MaskSlidInAnimation>

        <ScrollcompontentListelement
          colorvariant="blue"
          sizevariant="wide"
          title="Best multiple expectation."
          text1="tokentus invests in equity as well as tokens of companies in which it already holds equity."
          text2="Less is more. Therefore, there is a reduction and focus on exactly two types of investments. Equity and token. We only subscribe to tokens issued by companies we have already made equity investments into. In these two investment variants, there is currently an expectation to obtain the best possible investment multiple. This creates a clear demarcation. We will not invest in classic cryptocurrencies such as Bitcoin or Ether."
        />

        <ScrollcompontentListelement
          colorvariant="blue"
          sizevariant="wide"
          title="Invest in growth and later stage."
          text1="Investments mainly in growth and later stage, together with top co-investors results in the best risk-reward ratio."
          text2="tokentus is a venture capitalist firm. Nevertheless, we want to make the risk calculable. We achieve this by investing in companies that are a bit more mature, stick to solid business plans and, above all, are led by an experienced management team. A good management and strong co-investors are good ingredients for getting crises under control. This positioning is more expensive, but safer."
        />

        <ScrollcompontentListelement
          colorvariant="blue"
          sizevariant="wide"
          title="Entry through the industry."
          text1="Where does the blockchain technology help to disrupt classic business models? This is where we start to look for targets."
          text2="Blockchain is only the means and not the end. The classic sectors such as finance, real estate or insurance will continue to exist. When selecting companies that could be interesting for an investment, a relevant industry is first identified and then we search for companies that can use blockchain technology to gain competitive advantages and a leading market position within this industry."
        />

        <ScrollcompontentListelement
          colorvariant="blue"
          sizevariant="wide"
          title="In the right international investor team."
          text1="The integration into a global co-investor network is the real key to success in the search for first-class targets."
          text2="tokentus now has reliable relationships with co-investors who belong to the first league of blockchain investors worldwide. This opens the door for very interesting, high-quality, but also advanced investments that are predominantly international. Being part of a good investor team is all the more important because the really good and coveted targets can already choose their investors when they fill their financing rounds."
        />

        <ScrollcompontentListelement
          colorvariant="blue"
          sizevariant="wide"
          title="Legal certainty and transparency."
          text1="tokentus wants to be a legally sound partner for its shareholders, its portfolio companies, and its co-investors."
          text2="tokentus offers professionalism through a stock exchange listing in Germany (Xetra, stock exchanges in Frankfurt, Munich, Berlin) in addition to bookable, regulated tradable and priced shares as well as transparency through press releases, ad-hoc news, reports, publication of the annual financial statements and directors' dealings. An open book strategy creates trust. And that is what it is about."
        />

        <ScrollcompontentListelement
          colorvariant="blue"
          sizevariant="wide"
          title="Professional seniority as a standard."
          text1="The tokentus shareholders are interwoven with a large number of high-quality investors. Professional seniority is the common denominator."
          text2="Above all, the character of a company is shaped by the people who lead and own it. Of course, the blockchain is a new, promising and lucrative topic with great opportunities, but also with dangers. It depends on the images you have in your mind. And the longer you've been in the business and the more you've seen, the greater the wealth of experience against which you can evaluate and benchmark blockchain development."
        />
      </Container>
    </ScrollComponent>
  );
}

export default ScrollComponent6LawsCore;
