import { Box, Container } from "@chakra-ui/react";
import React from "react";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import GImage from "../DefinitionComponents/Utility/GImage";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
const links = [
  {
    id: 1,
    text: "Adhara",
    url: "/targets/adhara",
    imgurl: "targets/tc-adhara.png",
  },
  { id: 2, text: "BCB", url: "/targets/bcb", imgurl: "targets/tc-bcb.png" },
  {
    id: 3,
    text: "Blink Labs",
    url: "/targets/blinklabs",
    imgurl: "targets/tct-blinklabs.png",
  },
  {
    id: 4,
    text: "Breach",
    url: "/targets/breach",
    imgurl: "targets/tc-breach.png",
  },

  {
    id: 5,
    text: "Estating",
    url: "/targets/estating",
    imgurl: "targets/tc-estating.png",
  },
  {
    id: 5,
    text: "Faction",
    url: "/targets/faction",
    imgurl: "targets/tc-faction.png",
  },
  {
    id: 5,
    text: "Frachtis  ",
    url: "/targets/frachtis",
    imgurl: "targets/tc-frachtis.png",
  },
  {
    id: 6,
    text: "Gumi",
    url: "/targets/gumi",
    imgurl: "targets/tc-gumi.png",
  },
  {
    id: 7,
    text: "Hydra Ventures",
    url: "/targets/hydraventures",
    imgurl: "targets/tc-hydra.png",
  },
  {
    id: 7,
    text: "Iveone",
    url: "/targets/iveone",
    imgurl: "targets/tc-iveone.png",
  },
  {
    id: 7,
    text: "Intract",
    url: "/targets/intract",
    imgurl: "targets/tc-intract.png",
  },
  {
    id: 8,
    text: "Jiritsu",
    url: "/targets/jiritsu",
    imgurl: "targets/tc-jiritsu.png",
  },
  {
    id: 8,
    text: "Lava Network",
    url: "/targets/lavanet",
    imgurl: "targets/tc-lava.jpg",
  },
  {
    id: 8,
    text: "Nayms",
    url: "/targets/nayms",
    imgurl: "targets/tc-nayms.png",
  },

  {
    id: 9,
    text: "Ownera",
    url: "/targets/ownera",
    imgurl: "targets/tc-ownera.png",
  },
  {
    id: 10,
    text: "Playground",
    url: "/targets/playground",
    imgurl: "targets/tct-playground.png",
  },
  {
    id: 11,
    text: "Polysign",
    url: "/targets/polysign",
    imgurl: "targets/polysign.png",
  },
  {
    id: 12,
    text: "Qredo",
    url: "/targets/qredo",
    imgurl: "targets/tc-qredo.png",
  },
  {
    id: 13,
    text: "Ripple",
    url: "/targets/ripple",
    imgurl: "targets/tc-ripple.png",
  },
  {
    id: 14,
    text: "Spacemesh",
    url: "/targets/spacemesh",
    imgurl: "targets/tc-spacemesh.png",
  },
  {
    id: 15,
    text: "SwissBorg",
    url: "/targets/swissborg",
    imgurl: "targets/tc-swissborg.png",
  },

  {
    id: 17,
    text: "Transak",
    url: "/targets/transak",
    imgurl: "targets/tc-transak.png",
  },
  {
    id: 18,
    text: "XRP",
    url: "/targets/xrp",
    imgurl: "targets/tc-xrp.png",
  },
];
function PortfolioGrid() {
  return (
    <InViewTrigger threshold={0.4} triggerOnce={true}>
      {(inView) => (
        <Container
          variant="layoutContainer"
          mt={0.5}
          mb={0.5}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gridGap={0.5}
          gridTemplateColumns={{
            lg: "repeat(6, 1fr)",
            md: "repeat(4, 1fr)",
            sm: "repeat(3, 1fr)",
            base: "repeat(3,1fr)",
          }}
        >
          {links.map((link) => (
            <Box maxW={{ base: "45%", lg: "20%" }}>
              <FadeInAnimation
                threshold={0.4}
                delay={link.id * 0.1}
                duration={0.7}
                initialX={0}
                h="100%"
                initialY={10}
                whileHover={{ scale: 1.2 }}
              >
                <RouterLink
                  link={link.url}
                  key={link.id}
                  display="block"
                  minH={{ base: "20vh", lg: "200px" }}
                  w="100%"
                  p={{ base: 0, lg: 8 }}
                  isExternal={false}
                >
                  <GImage src={link.imgurl} />
                </RouterLink>
              </FadeInAnimation>
            </Box>
          ))}
        </Container>
      )}
    </InViewTrigger>
  );
}

export default PortfolioGrid;
