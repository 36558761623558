import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import MaskSlidInAnimation from '../DefinitionComponents/Animation-Components/MaskSlideInAnimation';
import InViewTrigger from '../DefinitionComponents/Utility/UX/InViewTrigger';

function ScrollcompontentListelement({
  title,
  text1,
  text2,
  colorvariant,
  sizevariant,
}) {
  return (
    <Box
      mb={20}
      maxW="100vw">
      <InViewTrigger
        threshold={0.4}
        triggerOnce={false}>
        {(inView) => (
          <Flex
            flexWrap="wrap"
            direction={{ base: 'column', md: 'row', lg: 'row' }}>
            <Box
              w={{ base: '100%', md: '35%' }}
              minW={{ base: '100%', md: '35%' }}
              maxW={{ base: '100%', md: '35%' }}>
              <MaskSlidInAnimation
                threshold={1}
                duration={0.6}>
                <Heading
                  fontSize={{ base: '3xl', lg: '5xl' }}
                  fontWeight="normal"
                  mb="4"
                  w={'100%'}
                  letterSpacing="tighter"
                  color={colorvariant == 'blue' ? 'brand.blue' : 'brand.green'}>
                  {title}
                </Heading>
              </MaskSlidInAnimation>
            </Box>
            <Box
              w={{ base: '100%', md: '65%' }}
              maxW={{ base: '100%', md: '65%' }}>
              <FadeInAnimation
                threshold={0.4}
                duration={1}
                delay={0.4}>
                <Heading
                  fontSize={{ base: '2xl', lg: '3xl' }}
                  fontWeight="normal"
                  mb="4"
                  color={
                    colorvariant == 'blue' ? 'brand.blue' : 'brand.lightgray'
                  }>
                  {text1}
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                duration={1}
                delay={0.8}>
                <Heading
                  fontSize={{ base: 'md', md: 'lg' }}
                  fontWeight="normal"
                  color={
                    colorvariant == 'blue' ? 'brand.black' : 'brand.medgray'
                  }>
                  {text2}
                </Heading>
              </FadeInAnimation>
            </Box>
          </Flex>
        )}
      </InViewTrigger>
    </Box>
  );
}

export default ScrollcompontentListelement;
